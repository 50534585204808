import React from 'react';

const Notfound = () => {
    window.location.replace("https://mzofficetest.click/");
    console.log("Not found. go to main page");
    return (<div>
        <h1>NotFound</h1>
    </div>    );
}

export default Notfound;