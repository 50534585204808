import mz1 from '../result_image/1_park.PNG';
import mz2 from '../result_image/2_um.png';
import mz3 from '../result_image/3_go.PNG';
import mz4 from '../result_image/4_kim.jpg';
import mz5 from '../result_image/5_ju.jpg';
import mz6 from '../result_image/6_jung.PNG';
import mz7 from '../result_image/7_kimm.PNG';
import mz8 from '../result_image/8_jang.PNG';
import mz9 from '../result_image/9_kim.PNG';
import mz10 from '../result_image/10_jico.PNG';
import mz11 from '../result_image/11_lee.PNG';
// import mz12 from '../result_image/12_mimi.PNG';
import mz13 from '../result_image/13_ji.jpg';
import mz14 from '../result_image/14_lee.png';
import mz15 from '../result_image/15_choi.PNG';
import mz16 from '../result_image/16_kim.PNG';

import ans1 from '../result_txt_image/result1.png'; //-8
import ans2 from '../result_txt_image/result2.png'; //-7 
import ans3 from '../result_txt_image/result3.png'; //-6
import ans4 from '../result_txt_image/result4.png'; //-5
import ans5 from '../result_txt_image/result5.png'; //-4
import ans6 from '../result_txt_image/result6.png'; //-3
import ans7 from '../result_txt_image/result7.png'; //-2
import ans8 from '../result_txt_image/result8.png'; //-1
import ans9 from '../result_txt_image/result9.png'; //0
import ans10 from '../result_txt_image/result10.png'; //1
import ans11 from '../result_txt_image/result11.png'; //2
//import ans12 from '../result_txt_image/result12.PNG';
import ans13 from '../result_txt_image/result13.png';
import ans14 from '../result_txt_image/result14.png';
import ans15 from '../result_txt_image/result15.png';
import ans16 from '../result_txt_image/result16.png';

import styled from 'styled-components';

import './resultText.css'


export const ResultData = [
  {
    id: 8,
    token: 'cf4175fd7b',
    name: '말의 앞뒤가 다른 최악의 상사',
    comment: "아이X발 주현 형! 아니 뭐 기본적인 거 이것도 찾아보지 않은겁니까? 예?",
    image: mz1,
    r_image: ans1,
    borderColor : 'white',
  },
  {
    id: 7,
    token: '40e482eaa0',
    name: '무서운 상사의 대표',
    comment: "여기는 회사지 뭐 학교나 학원 그런 곳이 아니잖아요?",
    image: mz2,
    r_image: ans3,
    borderColoer : 'white',
  },
  {
    id: 6,
    token: 'ad0bbf2599',
    name: '트렌디한척 하는 킹받는 아재',
    comment : "아... 나 고수 내가 생각해도 존나 트렌드했어",
    image: mz3,
    r_image: ans2,
    borderColor : 'white',
  },
  
  {
    id: 5,
    token: 'a42b7064df',
    name: '사내 파벌 싸움 조정자',
    comment : "이게 사회생활 만렙이다.. 이거면 환심사겠지?",
    image: mz4,
    r_image: ans4,
    borderColor : 'white',
  },
  {
    id: 4,
    token: '64334b1b4a',
    name: '이 시대의 진정한 젊꼰',
    comment : "미친 한 살 많다고 바로 반존대 까는거임? 막내야 ~ 수저 세팅 안 하고 뭐 하고 있니 ~?",
    image: mz5,
    r_image: ans5,
    borderColor : 'white',
  },
  {
    id: 3,
    token: 'd2d63f789a',
    name: '그나마 제일 정상적인 팀장님',
    comment : "잘 해주니까 누굴 알로 보나",
    image: mz6,
    r_image: ans6,
    borderColor : 'white',
  },
  {
    id: 2,
    token: 'b3b1d185e4',
    name: '손하나 까딱하기 싫은 꼰대',
    comment : "반찬 떨어진 거 안 보이냐 이것들아",
    image: mz7,
    r_image: ans7,
    borderColor : 'white',
  },
  {
    id: 1,
    token: '0838bab7a4',
    name: '나도 이정도면 MZ겠지? 제발요',
    comment : "오늘 첫 출근이라 잘 보이려고 (현영씨) 책상 좀 정리해 봤는데..",
    image: mz8,
    r_image: ans8,
    borderColor : 'white',
  },
  {
    id: 0,
    token: '15789b70e2',
    name: '사직서와 물아일체 원훈띠',
    comment:"(이번달에 차를 뽑으면 안됐었는데 ..) (엄마 죄송해요 저 다시 백수될거같아요) 그럼 제가 갔다올게요",
    image: mz9,
    r_image: ans9,
    borderColor : 'white',
  },
  {
    id: -1,
    token: '15789b70e2',
    name: '사직서와 물아일체 원훈띠',
    comment:"(이번달에 차를 뽑으면 안됐었는데 ..) (엄마 죄송해요 저 다시 백수될거같아요) 그럼 제가 갔다올게요",
    image: mz9,
    r_image: ans9,
    borderColor : 'white',
  },
  {
    id: -2,
    token: 'bfaf148cd7',
    name: '실력은 없으면서 자존감은 호날두급',
    comment:"차 빼드릴까요?",
    image: mz10,
    r_image: ans10,
    borderColor : 'white',
  },
  {
    id: -3,
    token: '82791fa00c',
    name: '쩝쩝박사 대리님',
    comment: "이런거에 내가 넘어갈줄 알고? 달다...",
    image: mz11,
    r_image: ans11,
    borderColor : 'white',
  },
  {
    id: -4,
    token: '0ac11dbcee',
    name: '맑은 눈의 광인',
    comment:'노래 들으면서 일해야 능률이 올라가는 편 입니다.',
    image: mz16,
    r_image: ans16,
    borderColor : 'white',
  },
  {
    id: -5,
    token: '61f4343a13',
    name: '복세편살(복잡한 세상 편하게 살자)',
    comment:'아.. 필라 가야 하는데.. (웃음웃음) 이지적이요? 하나도 안쉬워보이세요 !',
    image: mz13,
    r_image: ans13,
    borderColor : 'white',
  },
  {
    id: -6,
    token: 'bdd7320e1e',
    name: '중요한 건 꼰대에 꺾이지 않는 마음',
    comment:'걸어봐 위엄 라잌 어 라이옹 ~ 뭐 꼬우세요? 나이도 어린 ㄴ이',
    image: mz14,
    r_image: ans14,
    borderColor : 'white',
  },
  {
    id: -7,
    token: '0ac11dbcca',
    name: '속전속결! 속앓이는 나한테만 손해야',
    comment:'오늘 일한 3시간 일당은 입금 부탁드립니다',
    image: mz15,
    r_image: ans15,
    borderColor : 'white',
  },

  {
    id: -8,
    token: '0ac11dbcee',
    name: '맑은 눈의 광인',
    comment:'노래 들으면서 일해야 능률이 올라가는 편 입니다.',
    image: mz16,
    r_image: ans16,
    borderColor : 'white',
  },

]

const DescSub = styled.div`
  font-size: 15px;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 5px;
  black-space: pre-wrap;
  font-family: "CookieRun_Regular";

`