import React from 'react';
import styled from 'styled-components';
import { ProgressBar, Button } from 'react-bootstrap';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { QuestionData } from '../assets/data/questiondata';
import Footer from './footer';
import Adfit from "./Adfit";
import "../assets/fonts/font.css"
import ReactDOM from 'react-dom'
import Snowfall from 'react-snowfall'

let randomValue;
const Question = () => {
  const [mzScore, setMzScore] = React.useState();
  const [questionNo, setQuestionNo] = React.useState(0);
  const [totalScore, setTotalScore] = React.useState([
    { id: "MZ", score: 0 },
  ]);

  let mzScoreResult;
  const navigate = useNavigate();
  const questionNum = 8;

  const handleClickBtn = (id, data, type) => { //id - 1 2 3 4 5 6 //data - 1 0 //type - LOVE SUCCESS MONEY
    const newScore = totalScore.map((s) =>
      s.id === type ? { id: s.id, score: s.score + data } : s
    );
    console.log(`id: ${id}`);
    console.log(`data: ${data}`);
    console.log(`type: ${type}`);
    console.log(`newScore[0].score: ${newScore[0].score}`);
    console.log("");
    window.scrollTo(0, 0);
    setTotalScore(newScore);
    if (id <= questionNum) {
      setQuestionNo(questionNo + 1);
      if (id == questionNum) {
        mzScoreResult = newScore;
        setMzScore(mzScoreResult[0].score);
        console.log('MZ 계산: ', mzScoreResult);
        console.log('MZ 계산: ', mzScoreResult[0].score);
      }
    }
  }

  const showQuestionNum = (id) => {
    if (id <= questionNum) return (<QuestionNo> 질문.{id} </QuestionNo>)
  };

  const showTitle = (id) => {
    if (id <= questionNum) { return (<Title>{QuestionData[questionNo].title}</Title>); }
    else { return (<TitleSub>{QuestionData[questionNo].title}</TitleSub>) } //쿠팡 
  }

  const ShowButton = (id) => {
    if (id <= questionNum){
    return (
      <ButtonGroup>
        <Button onClick={()=>handleClickBtn(id, 1, QuestionData[questionNo].type)} style={{width:"80%", minHeight: "12vh", fontSize:"15px", margin:"7px 7px 7px 7px"}}>{QuestionData[questionNo].answerA}</Button>
        <Button onClick={()=>handleClickBtn(id, -1, QuestionData[questionNo].type)} style={{width:"80%", minHeight: "12vh", fontSize:"15px", margin:"7px 7px 7px 7px"}}>{QuestionData[questionNo].answerB}</Button>
      </ButtonGroup>
    );
  } else { //쿠팡
    return (
      <div>
      <ButtonGroup>
        <Button onClick={()=>Notice(mzScore)} style={{"background-color": "green", "border": "none", width:"80%", minHeight: "15vh", fontSize:"17px", margin:"7px 7px 7px 7px"}}>{QuestionData[questionNo].answerA}</Button>
      </ButtonGroup>
      <FooterWrapper>
      이 포스팅은 쿠팡 파트너스 활동의 일환으로, <br/>이에 따른 일정액의 수수료를 제공받습니다.<br/><br />
      <iframe src="https://ads-partners.coupang.com/widgets.html?id=712312&template=carousel&trackingCode=AF6214453&subId=&width=230&height=60&tsource=" width="230" height="60" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>
    </FooterWrapper>
    
    </div>
    )
  }
  }

  const ShowAd1 = (id) => {
    if (id <= questionNum) {
      return (
        <Adfit height={50} width={320} unit={"BBDjRY9ejjNc3DzE"} scrFlag={true} position={"middle"}></Adfit>
      );
    }
  }

  const ShowAd2 = (id) => {
    if (id <= questionNum) {
      return (
        <Adfit height={100} width={320} unit={"myY6JOi4A39GHzHW"} scrFlag={true} position={"buttom"}></Adfit>
      );
    }
  }

  const Notice = (rabbit) => {
    console.log('rabbit: ', rabbit);
    window.open('https://link.coupang.com/a/8S5YI', '_blank');
    navigate(`/result/${setURLparams(rabbit)}`);
  }

  const randomPick = (mzScore) => {
    randomValue = Math.floor(Math.random()*2);
    console.log(`randomValue: ${randomValue}`);
    return randomValue;
  };

  const setURLparams = (mzScore) => {
    switch (mzScore) {
      case -8:
        return randomPick(mzScore) ? '0ac11dbcee' : '0ac11dbcca'
      case -6:
        return randomPick(mzScore) ? 'bdd7320e1e' : '61f4343a13'
      case -4:
        return randomPick(mzScore) ? '0ac11dbcee' : '82791fa00c'
      case -2:
        return randomPick(mzScore) ? 'bfaf148cd7' : '15789b70e2'
      case 0:
        return randomPick(mzScore) ? '0838bab7a4' : '15789b70e2'
      case 2:
        return randomPick(mzScore) ? '0838bab7a4' : 'b3b1d185e4'
      case 4:
        return randomPick(mzScore) ? '8d2d63f789a' : '64334b1b4a'
      case 6:
        return randomPick(mzScore) ? 'a42b7064df' : '40e482eaa0'
      case 8:
        return randomPick(mzScore) ? 'ad0bbf2599' : 'cf4175fd7b'
    }

  }
  return (
    <div style={{}}>
      {/* <Snowfall snowflakeCount={50} wind={[0.5, 2.0]} /> */}
        <Wrapper>
            <Bar>
              <ProgressBar striped now={(questionNo/QuestionData.length)*100}/>
            </Bar>
            {showQuestionNum(QuestionData[questionNo].id)}
            <br/>
            <QImage>
              <img alt='question_img' 
              src = {QuestionData[questionNo].img} width={300} hegiht={300}
              className="rounded-circles"
              style={{ borderRadius: '10%', border: '1px solid ' + 'white' }}
              />
            </QImage>

            {showTitle(QuestionData[questionNo].id)}
            
            {/* {ShowAd1(QuestionData[questionNo].id)}
            <br/> */}
            {ShowButton(QuestionData[questionNo].id)}
            <Footer></Footer>
            {ShowAd2(QuestionData[questionNo].id)}
        </Wrapper>
        </div>
    );
}

export default Question;

const FooterWrapper = styled.div`
  font-size: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: white;
  text-align: center
`

const QImage = styled.div`
  text-align: center;
`

const Wrapper = styled.div`
  //height: 100vh;
  height: 100%;
  width: 100%;
  font-family: "CookieRun_Regular";
  background: linear-gradient(to bottom, black, #16004d);
  text-align: center
  white-space: pre-line;

  `
const Bar = styled.div`
  padding-top: 2rem;
`
const QuestionNo = styled.div`
  margin: 2rem 1rem 1rem 1rem;
	font-size: 20px;
	text-align: center;
  font-weight: 500;
  color: white;

`
const Title = styled.div`
  padding: 1rem 1rem 1rem 1rem;
	font-size: 17px;
	text-align: center;
  color: white;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
`
const TitleSub = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  font-size: 17px;
  text-align: center;
  color: white;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
  `

const ButtonGroup = styled.div`
	display: flex;
	flex-direction : column;
	align-items: center;
	justify-content: center;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";

`
