import Home from './pages/Home';
import Question from './pages/Question';
import Result from './pages/Result';
//import Blog from './pages/Blog';
import { Routes, Route } from 'react-router-dom';
import Notfound from './pages/Notfound';
import './App.css';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />}></Route>
      <Route path='/question' element={<Question />}></Route>
      <Route path='/question/*' element={<Question />}></Route>
      <Route path='/result/:rabbit' element={<Result />}></Route>
      <Route path="/*" element={<Notfound />} /> 
    </Routes>
  );
}

export default App;
