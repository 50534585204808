import noticeImg from '../result_image/notice.PNG';
import QImage1 from '../question_img/Q1.png';
import QImage2 from '../question_img/Q2.png';
import QImage3 from '../question_img/Q3.png';
import QImage4 from '../question_img/Q4.png';
import QImage5 from '../question_img/Q5.png';
import QImage6 from '../question_img/Q6.png';
import QImage7 from '../question_img/Q7.png';
import QImage8 from '../question_img/Q8.png';
// import noticeImg from '../notice.png';


export const QuestionData = [
  {
    id: 1,
    title: "어제 들어온 신입이 에어팟을 끼고 일하고 있다. 나는?",
    answerA: "미친 거 아닌가?",
    answerB: "부를일 있으면 메신저로 해야겠네~",
    type: "MZ",
    img:QImage1,
  },
  {
    id: 2,
    title: "누군가 커피를 픽업하러 가야된다. 나는?",
    answerA:      "커피 안 가져오냐 이것들아?",
    answerB:      "사다리 타기해서 몰빵?",
    type: "MZ",
    img:QImage2,

  },
  {
    id: 3,
    title: "나이가 나보다 많은 신입이 나이를 들먹인다.",
    answerA: "어쩌라고. 꼬우면 먼저 들어오든가^^",
    answerB: "내가 더 어리네? 개이득",
    type: "MZ",
    img:QImage3,

  },
  {
    id: 4,
    title: "복사기 용지부족 경고가 들린다 나는?",
    answerA: "모른척 하는 거 개킹받네",
    answerB: "필요한 사람이 채워서 쓰겠지?",
    type: "MZ",
    img:QImage4,

  },
  {
    id: 5,
    title: "신입사원이 회의에 늦었다 나는?",
    answerA: "휴 이해해야지 쿨내 미쳤다~",
    answerB: "뭐 1~2분동안 별얘기 하지도 않았는데",
    type: "MZ",
    img:QImage5,

  },
  {
    id: 6,
    title: "반찬이 다 떨어졌는데 다들 밥에만 집중한다. 나는?",
    answerA: "언제까지 내가 반찬을 퍼와야 되지?",
    answerB: "먹고 싶은 사람이 퍼오겠지 머",
    type: "MZ",
    img:QImage6,

  },
  {
    id: 7,
    title: "몸이 피곤한데, 갑자기 팀장님이 회식을 가자고 한다.",
    answerA: "회식도 업무의 연장선이니 간다..",
    answerB: "아 저는 선약이 있어서ㅎㅎ.. 둘러댄다",
    type: "MZ",
    img:QImage7,

  },
  {
    id: 8,
    title: "신입사원이 조용히 브이로그를 촬영하는걸 발견했다. 나는?",
    answerA: "제정신인가? 말로만 듣던 일이 내눈앞에..",
    answerB: "시간 안에 자기 일만 잘하면 되지 ~",
    type: "MZ",
    img:QImage8,

  },
  {
    id: 9,
    title: "안정적인 서버 운영비 조달을 위해\n쿠팡 광고를 삽입했어요😅\n\n쿠팡 광고 뜨면 놀라지 마시고\n 뒤로가기 누르시면 결과 확인하실 수 있어요🙏",
    answerA: "쿠팡 광고보고 결과 확인하기!",
    answerB: "X",
    type: "",
    img: noticeImg,
  }
];